import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Home() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}landing`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return (
            <div className="relative isolate overflow-hidden bg-white p-6 md:p-16">
                <div className="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">Kesalahan @ListApp {error}</div>
            </div>
        )
    } else if (!isLoaded) {
        const nomor = [1, 2, 3, 4, 5, 6, 7, 8];
        return (
            <div className="relative isolate overflow-hidden bg-white py-8">
                <div className="bg-white">
                    <div className="mx-auto max-w-7xl px-6 md:px-16">
                        <div className="mx-auto sm:mx-0 mb-8">
                            <div className="flex items-center">
                                <div className="flex-none me-5">
                                    <img className="w-20" src="mucis.ico" alt="Logo" />
                                </div>
                                <div className="flex-auto w-full items-center">
                                    <h2 className="text-2xl font-bold tracking-tight text-blue-950 sm:text-4xl mb-2">Landing Page</h2>
                                    <p className="text-sm sm:text-lg text-gray-700">
                                        Silahkan klik pada gambar di bawah untuk menuju web yang dimaksud.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-6 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-6 sm:space-y-0">
                            {nomor.map((no) => (
                                <div key={no} className="group animate-pulse">
                                    <div className="bg-slate-300 rounded-xl h-56">

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className="relative isolate overflow-hidden bg-white py-8">
                    <img
                        src="bg.jpg"
                        alt="Background"
                        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                    />
                    <div
                        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div
                        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-6 md:px-16">
                        <div className="mx-auto sm:mx-0 mb-8">
                            <div className="flex items-center">
                                <div className="flex-none me-5">
                                    <img className="w-20" src="mucis.ico" alt="Logo" />
                                </div>
                                <div className="flex-auto w-full items-center">
                                    <h2 className="text-2xl font-bold tracking-tight text-blue-950 sm:text-4xl mb-2">Landing Page</h2>
                                    <p className="text-sm sm:text-lg text-gray-700">
                                        Silahkan klik pada gambar di bawah untuk menuju web yang dimaksud.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 sm:gap-6 sm:space-y-0">
                            {datas.map((list, i) => (
                                <div key={i} className="w-full bg-white rounded-xl p-4 shadow-lg">
                                    <div className="h-min overflow-hidden rounded-lg mb-3">
                                        <a href={list.link} target={list.nama} rel="noreferrer noopener">
                                            <LazyLoadImage src={list.src_gambar} className="hover:scale-125 transition-all duration-500 cursor-pointer"
                                                alt={list.nama}
                                            />
                                        </a>
                                    </div>
                                    <a href={list.link} target={list.nama} rel="noreferrer noopener" className="mb-2 hover:text-blue-900 font-bold text-gray-900 ">{list.nama}</a>
                                    <p className="mb-0 text-sm font-normal text-gray-500 ">{list.deskripsi}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-blue-950 text-white">
                    <div className="mx-auto max-w-7xl px-6 md:px-16 py-6">
                        <div className="flex flex-col sm:flex-row sm:justify-between gap-2 text-sm font-light">
                            <p className="text-center sm:text-left">
                                &copy; STIKes Muhammadiyah Ciamis - 2023
                            </p>
                            <p className="text-center sm:text-right">Dikembangkan oleh Tim IT Mucis</p>
                        </div>
                    </div>
                </div >
            </>
            // <div className="relative isolate overflow-hidden bg-white">
            //     <div className="mx-auto max-w-7xl px-6 sm:px-14 lg:px-20">
            //         <div className="mx-auto py-6 lg:max-w-none">
            //             <h2 className="text-2xl font-bold text-gray-900 text-center mb-6">Daftar Link</h2>

            //             <div className="space-y-6 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-6 sm:space-y-0">
            //                 {datas.map((list, i) => (
            //                     <div key={i} className="w-full bg-white border border-gray-200 rounded-xl shadow p-4">
            //                         <div className="h-min overflow-hidden rounded-lg mb-4">
            //                             <a href={list.link} target={list.nama} rel="noreferrer noopener">
            //                                 <img className="hover:scale-125 transition-all duration-500 cursor-pointer" src={list.src_gambar} alt={list.nama} />
            //                             </a>
            //                         </div>
            //                         <a href={list.link} target={list.nama} rel="noreferrer noopener" className="mb-2 hover:text-blue-900 font-bold text-gray-900 ">{list.nama}</a>
            //                         <p className="mb-0 text-sm font-normal text-gray-500 ">{list.deskripsi}</p>
            //                     </div>
            //                 ))}
            //             </div>
            //         </div>
            //     </div>
            // </div >
        )
    }
}